<template>
	<main>
		<div id="top" nitrohosting-id="NZAfJEPNJ6DmhOFJ" class="nitrohosting-row nitrohosting-row-stretch-full nitrohosting-height-fit">
			<div class="nitrohosting-background-overlay"></div>
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-full">
				<div nitrohosting-id="VZzubBuh1wsjlgxC" class="nitrohosting-col nitrohosting-col-9">
					<div class="nitrohosting-col-holder">
						<div nitrohosting-id="vUoaDvD7aJT6XdQx" class="nitrohosting-heading" style="text-align: left;">
							<div class="nitrohosting-heading-holder">Game Hosting</div>
						</div>
						<div nitrohosting-id="NTzQbXPdkLST6jm2" class="nitrohosting-heading" style="text-align: left;">
							<div class="nitrohosting-heading-holder">Minecraft</div>
						</div>
					</div>
				</div>
				<div nitrohosting-id="hnZa2C1jLJksHtRt" class="nitrohosting-col nitrohosting-col-3 nitrohosting-hide-mobile">
					<div class="nitrohosting-col-holder">
						<div nitrohosting-id="NfBEo0UgNPFai60p" class="nitrohosting-btn nitrohosting-hide-mobile">
							<a v-scroll-to="'#pricing'" class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-right">
								<span class="nitrohosting-btn-text">View Plans</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="feature" nitrohosting-id="nzkqawYBf1UHHDIK" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-background-overlay"></div>
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="YEWeaI0sARX2xrR7" class="nitrohosting-col nitrohosting-col-6">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="Reh5yr61vAbTLo2j" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder">Features</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="WRSyv4cdiKGRax1v" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div nitrohosting-id="7Q93yJXf6bKbJt2H" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="YHgVL8Pp3ShbLiDp" class="nitrohosting-col">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="6QUTQQhImsiA81ub" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-microchip" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">High Clock Speed CPU</div>
													<div class="nitrohosting-service-text">
														<p>Our servers are equipped with high speed CPU on every plans.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="FcIzi30AieYI5UBn" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-hdd" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">RAID M.2 NVMe Storage</div>
													<div class="nitrohosting-service-text">
														<p>Delivering extreme performance and protecting your data.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="sYmyDWkZ64JpNrhN" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-network-wired" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Balanced Server Loads</div>
													<div class="nitrohosting-service-text">
														<p>We have balanced system load, avoiding unexpected downtime.<br />
														</p></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="yVdef2MziO4ev6jc" class="nitrohosting-col">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="vvvh43htrfPNEVge" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-memory" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">DDR4 ECC RAM</div>
													<div class="nitrohosting-service-text">
														<p>We use modern DDR4 ECC RAM preventing data corruption.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="TRqkvk2yxBfgiwTo" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-shield-alt" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Advanced DDoS Protection</div>
													<div class="nitrohosting-service-text">
														<p>Our DDoS mitigation technology scan and block malicious traffic.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="yZZgddIC2s0VOAHe" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-user-friends" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Quality Support</div>
													<div class="nitrohosting-service-text">
														<p>Our staffs are well trained and knowledgeable, all ready for you.<br />
														</p></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="pricing" nitrohosting-id="n3yH0d9NMK4fz9uy" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-background-overlay"></div>
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="D9WkR4Hqmrevxc3Z" class="nitrohosting-col nitrohosting-col-6">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="ZYB2vR98WMdpH4DH" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder">Plans and Pricing</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="snZhEtU7mZ4TUCN4" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="35zRkLDObWjbI4MV" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="9Tx27gvprN6tUG08" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="tqz3sMr5x91EfAN3" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Plan</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="aMpEFao736iysYX0" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="ZIE6aj0SZ8lFPOoa" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">CPU</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="wrTyHnEpiTs3dqO9" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="v8oFJnY2jHl1avx6" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">RAM</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="HeeN65MpbBeM3Tpc" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="RDyP3mKspqPa8gUI" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Drive</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="dZ1IFk31SKBZdvUA" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="qKoESxqweCDIHJPY" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Database</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="QUsrjpWhcExSSjQ2" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="LmcOO5KBof2Szna4" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Price</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="fPLYxwPrHTsup23c" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-shape">
								<div class="nitrohosting-row-svg"></div>
							</div>
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="GjOhutleVFthVRG2" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="CmOzhLNQMHr5ywum" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> MC-1</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="bi2lwTzkfZj2SjnN" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="1jCdxP7EF1knEuWw" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 2GHz+</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="b3ORGPpALrznFTKI" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="5ZET8ywGevUmyZwQ" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 512MB DDR4</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="1A7rvCFgys1hGuWr" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="w1H8wbpLntVgFT1H" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 1GB NVMe</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="4Tx2yy2xsK5DNQ2x" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="DtWOXNr9ynfi8Oa2" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> None</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="5HfThiB10uJMTTIQ" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="glpPBi35ZYUTGJtZ" class="nitrohosting-btn">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-left" href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=5&amp;currency=1&amp;billingcycle=annually"><span class="nitrohosting-btn-text">$1/mo</span></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="wR3c02NwLWhCTzGV" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-shape">
								<div class="nitrohosting-row-svg"></div>
							</div>
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="htEzO7ifIBSEo4qE" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="dlylEjBGc1AWOFtC" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> MC-2</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="lHByCMYizxTQxfwF" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="SVXmijxsNJQp0MQq" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 3GHz+</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="aiers89EjTu63AvL" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="imKYsebpTX5Adaj4" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 1GB DDR4</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="Xmlypa4HuaB60peP" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="ws6VVPr0BpY81ttj" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 3GB NVMe</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="xrbUDMEyJFyAIHTL" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="GwvaMsDK1rFOpXub" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> None</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="x8BFkto36f6a90G7" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="xGncmGZKi5RkPRTD" class="nitrohosting-btn">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-left" href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=6&amp;currency=1&amp;billingcycle=quarterly"><span class="nitrohosting-btn-text">$2/mo</span></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="k4Coe81HccrWT68P" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-shape">
								<div class="nitrohosting-row-svg"></div>
							</div>
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="PFgzciF578AbXrR9" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="dCjOXp0az6MbOf6y" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> MC-3</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="MAOPMIru8y9Q40ZC" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="TWlWEtH0qDkX01WY" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 3GHz+</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="MqcuOx6dNG9AP2VC" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="e6O45XQCP7TP8sYc" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 2GB DDR4</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="NbKvSi6xj7cmpomG" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="1AzKYr8DcK0RQ8cH" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 5GB NVMe</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="QjuSlHhfo0vjupNI" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="4NO3v64hjFGECIh7" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 1</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="6CBTdEr3ne3N40YQ" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="gH7yaM11SD90I43L" class="nitrohosting-btn">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-left" href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=10&amp;currency=1&amp;billingcycle=monthly"><span class="nitrohosting-btn-text">$4/mo</span></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="v6BYiQtm1Sxcn8n7" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-shape">
								<div class="nitrohosting-row-svg"></div>
							</div>
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="E2AuxSF0iJ3kPFvt" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="zl6TqylCcjcrxczn" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> MC-4</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="5gMV9kP0jrthmAok" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="4gPKfmJBOa7hlhpd" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 3GHz+</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="eY9e4ZJeY5mnJCxQ" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="3JGX7FoZmVUYe7ly" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 4GB DDR4</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="asXGgvup1Odb0mWz" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="LbSWqXYacpAZY5jl" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 8GB NVMe</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="eTzwgczXAovopYGU" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="TtZsWStYPJNaEPT5" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 1</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="exJ7EQVRUPJ9Y2Ch" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="zo4mQXHUHEp6Nlro" class="nitrohosting-btn">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-left" href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=11&amp;currency=1&amp;billingcycle=monthly"><span class="nitrohosting-btn-text">$8/mo</span></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="0rFVuHo2jkuM7Qq7" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-shape">
								<div class="nitrohosting-row-svg"></div>
							</div>
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="FHnxOiZbuKOlJ7XK" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="uyHDsEmQqKKoq7Bv" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> MC-5</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="6ZD7s3PMXDUffEU6" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="e71Xtz16ayGVnzKJ" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 3GHz+</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="REiQ6qETtOx57kuY" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="640WjUTpfpoo6XOi" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 6GB DDR4</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="rcedJip0jEzLTHxB" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="6V5dRNS1pPDGBPHX" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 10GB NVMe</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="0OIw3dQnJEuqRp7m" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="6inM6no9Jy2SA5ZS" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 1</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="xnlPByRUUIicTFLi" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="ozP32ObIGsoVO7HK" class="nitrohosting-btn">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-left" href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=12&amp;currency=1&amp;billingcycle=monthly"><span class="nitrohosting-btn-text">$12/mo</span></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="eH7K9R2IemHB7hiY" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-shape">
								<div class="nitrohosting-row-svg"></div>
							</div>
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="un9NlKgdGZWBB2NB" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="hPCffA2jHDBEBLHu" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> MC-6</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="2xFs9UsH53x1JlDJ" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="m3pbg1s9zrT8RKjS" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 3GHz+</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="LEamixuvAELvxvgN" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="Z44yT0zOjWUsOhOW" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 8GB DDR4</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="HCIic9SEXRAXBbN3" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="Lw3DAWyDQ0HGxDh5" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 12GB NVMe</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="3XsyhoU1srs4s5wz" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="SFFqKuAVfQvJqFis" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 2</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="gwsywkvmF8xDRneY" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="IY8w9N86AHukB8Zu" class="nitrohosting-btn">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-left" href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=9&amp;currency=1&amp;billingcycle=monthly"><span class="nitrohosting-btn-text">$16/mo</span></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="gtWGsYgJBnRBfZ0b" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-shape">
								<div class="nitrohosting-row-svg"></div>
							</div>
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="aSW2zrUqO7O2RQ3a" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="m8mLt5UFOS3fghuI" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> MC-7</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="OcY8YHsr8ddaxDJ5" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="0JT0ajN6Elx4g0ZQ" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 3GHz+</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="v4ytEL74ZEpRjGsN" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="XmyRkrsem3S5rW9P" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 12GB DDR4</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="9p68YBbpJDZQAUeL" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="wttTV7z5ZUThJb7B" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 16GB NVMe</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="nVgPN7ZWJV97Snpx" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="BeQRJ0vGPqRef8m9" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 2</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="ybHRQO7UKutLWMkP" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="eavamZ6gMFjV7O2B" class="nitrohosting-btn">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-left" href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=8&amp;currency=1&amp;billingcycle=monthly"><span class="nitrohosting-btn-text">$24/mo</span></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="DinNvY3Ocouyevpj" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-shape">
								<div class="nitrohosting-row-svg"></div>
							</div>
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="a0VfP41YZwPLVkK6" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="VOu0lCwDa6ndvcl1" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> MC-8</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="ORzsoOith7hUPJKx" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="qu6ZRwG5hixFmaW0" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 3GHz+</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="PhlNzSa3TwFLukMZ" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="Svk7pmzjkYJwG1ue" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 16GB DDR4</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="uegvvVp9qW2GVvHg" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="0bTeY3aXKEoGeUCW" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 20GB NVMe</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="K6zAddxScBOnemGo" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="u4rE5QA074btBrqj" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> 3</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="iUJbAlwUZHtgfjrY" class="nitrohosting-col nitrohosting-col-2">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="weLXssSrJttzfNeS" class="nitrohosting-btn">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-small nitrohosting-btn-icon-left" href="https://billing.nitrohosting.pro/cart.php?extra=0&amp;&amp;a=add&amp;pid=7&amp;currency=1&amp;billingcycle=monthly"><span class="nitrohosting-btn-text">$32/mo</span></a>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="6w6nV0CkSjvYSrB1" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="5flT1XtLz47dlqWr" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="boPY0JWHdrC0VhEU" class="nitrohosting-heading" style="text-align: center;">
											<a href="https://billing.nitrohosting.pro/submitticket.php?step=2&amp;deptid=1&amp;subject=Requesting+Larger+Game+Server+Volume&amp;message=I+would+like+to+order+a+larger+game+server,+details+as+below:+%0A%0DCPU+Requirement:+%0DRAM+Requirement:+%0DStorage+Requirement:+%0DOthers:">
												<div class="nitrohosting-heading-holder"> Need a larger volume? Contact us for a tailored solution!</div>
											</a></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
	export default {
		name: "Minecraft",
		metaInfo: {
			title: "Minecraft",
			titleTemplate: "%s - NitroHosting"
		}
	};

	let header = document.querySelector(".nitrohosting-header > .nitrohosting-row");
	header.classList.remove("is-fixed");
</script>
<style scoped>
	@import "../assets/css/pages/Minecraft.css";
</style>